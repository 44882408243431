import React from 'react';
import { Link } from 'react-router-dom';

const sportsData = [
  {
    title: 'Football',
    imageUrl: 'https://aivg.azmiproductions.com/assets/FOOTBALL.png',
    link: '/football',
  },
  {
    title: 'Volleyball (Brothers)',
    imageUrl: 'https://aivg.azmiproductions.com/assets/VOLLEYBALL.png',
    link: '/volleyballb',
  },
  {
    title: 'Volleyball (Sisters)',
    imageUrl: 'https://aivg.azmiproductions.com/assets/VOLLEYBALL%20%28M%29.png',
    link: '/volleyballs',
  },
  {
    title: 'Basketball',
    imageUrl: 'https://aivg.azmiproductions.com/assets/BASKETBALL.png',
    link: '/basketball',
  },
  {
    title: 'Netball',
    imageUrl: 'https://aivg.azmiproductions.com/assets/NETBALL%20%28F%29.png',
    link: '/netball',
  },
  {
    title: 'Team Standings',
    imageUrl: 'https://aivg.azmiproductions.com/assets/NETBALL%20%28F%29.png',
    link: '/scoreboard',
  },
];

const Landing = () => {
  return (
    <div
      className="min-h-screen py-10 flex items-center justify-center"
      style={{
        backgroundImage: `url('https://tronova.azmiproductions.com/img/backy.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-black bg-opacity-60 rounded-lg shadow-lg p-8 text-center">
        
        {/* Add the image above the title */}
        <img
          src="https://tronova.azmiproductions.com/img/pocketnoback.png"  // Replace with the actual image URL
          alt="Event Logo"
          className="mx-auto mb-4 w-24 h-24" // Center the image, add margin, and size it
        />
        
        <h1 className="text-white text-2xl uppercase font-extrabold mb-4">Service Terminated</h1>
        <h1 className="text-white text-lg uppercase font-extrabold mb-12"> - Azmi Productions -</h1>
        <h1 className="text-white text-base uppercase font-extrabold mb-4">We were informed that the Mobile Unit IIUM will be having 6 buses with installed tracking for student used thus we will be terminating this services</h1>
        <h1 className="text-white text-sm uppercase font-extrabold mb-12">Thank you for your support to Azmi Productions. We did it ! TOWARDS BETTER SYSTEM</h1>
      {/*  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sportsData.map((sport, index) => (
            <div
              key={index}
              className="rounded-lg overflow-hidden shadow-md transform transition-transform duration-300 hover:scale-105"
              style={{
                backgroundImage: 'linear-gradient(135deg, #33888D, #000000)', // Gradient from #33888D to a darker shade
              }}
            >
              <img src={sport.imageUrl} alt={sport.title} className="w-full h-48 object-cover" />
              <div className="p-4 text-center">
                <h5 className="text-white text-xl font-semibold mb-2">{sport.title}</h5>
                <Link
                  to={sport.link}
                  className="inline-block mt-2 px-6 py-2 rounded-full bg-gray-700 text-white text-lg font-semibold transition-colors duration-300 hover:bg-gray-900"
                >
                  Watch
                </Link>
              </div>
            </div>
          ))}
        </div>
        */}
      </div>
      
    </div>
  );
};

export default Landing;
